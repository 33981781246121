import React from 'react'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import YouTubeIcon from '@material-ui/icons/YouTube'
import logo from '../../assets/images/logo.png'
import './styles.sass'

const Header = () => {
  return (
    <header>
      <a className="logo" href="https://www.padremartinho.com/">
        <img src={logo} alt="logo" />
      </a>
      <aside className="rss">
        <a
          href="https://www.facebook.com/HogarPadreMartinhoOficial/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookIcon />
        </a>
        <a
          href="https://www.instagram.com/hogar_padre_martinho/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramIcon />
        </a>
        <a
          href="https://www.youtube.com/channel/UC-L2yQox-nyLBeOIHmwg59A/videos"
          target="_blank"
          rel="noopener noreferrer"
        >
          <YouTubeIcon />
        </a>
      </aside>
      <div className="w_50 escribenos">
        ¡Vamos, escríbenos a{' '}
        <a href="mailto:hogarpadremartinho@gmail.com">
          hogarpadremartinho@gmail.com
        </a>{' '}
        si&nbsp;tienes&nbsp;alguna&nbsp;duda.
      </div>
    </header>
  )
}

export default Header
