import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { PayPalButton } from 'react-paypal-button-v2'
import './styles.sass'
import PriceCard from './priceCard/'

import img1 from '../../assets/images/miradas/img1.jpg'
import img2 from '../../assets/images/miradas/img2.jpg'
import img3 from '../../assets/images/miradas/img3.jpg'
import img4 from '../../assets/images/miradas/img4.jpg'
import { apiDolar, idCliente } from '../../settings/apiSettings'
import Header from '../header'

// SDK de Mercado Pago

const DonateProcess = (prop) => {
  const { information, className } = prop

  const [tipoDonacion, settipoDonacion] = useState(false)
  const [tipoMoneda, settipoMoneda] = useState(false)
  const [monto, setmonto] = useState(0)
  const [open, setOpen] = useState(false)
  const [cambioDolar, setcambioDolar] = useState(0)
  const [gracias, setgracias] = useState(false)

  const montos = [
    {
      monto: 20,
      imagen: img1,
      suscriptionPen: 'https://py.pl/1g4XUb',
      suscriptionUsd: 'https://py.pl/6FIc4e4zV9N',
    },
    {
      monto: 50,
      imagen: img2,
      suscriptionPen: 'https://py.pl/3M4ee7ykwsV',
      suscriptionUsd: 'https://py.pl/4x33YGBIR5O',
    },
    {
      monto: 100,
      imagen: img3,
      suscriptionPen: 'https://py.pl/1BDx1i',
      suscriptionUsd: 'https://py.pl/50kTAdM8b1s',
    },
    {
      monto: 200,
      imagen: img4,
      suscriptionPen: 'https://py.pl/IlLVK',
      suscriptionUsd: 'https://py.pl/GqbbS',
    },
  ]

  const handleUnica = () => {
    settipoDonacion(false)
  }

  const handleMensual = () => {
    settipoDonacion(true)
  }

  const handlePEN = () => {
    settipoMoneda(false)
  }

  const handleUSD = () => {
    settipoMoneda(true)
  }

  const handleClickOpen = (number) => {
    setOpen(true)

    setmonto(number)
    
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    const tipoCambio = () => {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

      fetch(
        apiDolar,
        requestOptions,
      )
        .then((response) => response.json())
        .then((result) => {
          const response = result.Cotizacion
          setcambioDolar(response[0].Compra)
        })
        .catch((error) => console.log('error', error))
    }
    tipoCambio()
  }, [])

  return (
    <div className={`section_middle_center contenedorFormulario ${className}`}>
      <h1>
        {information.name
          ? `Mucho gusto ${information.name}`
          : 'Antes de realizar su donación quisieramos conocerte.'}
      </h1>
      {information.name ? (
        <div className="w_100 section_middle_center">
          <p className="mensajeDonacion">
            Tu donación mensual nos permite seguir ayudando a los niños y niñas
            motivados por las enseñanzas que nos dejó nuestro querido Padre
            Martinho.
          </p>
          <h2 className="mensajeDonacion">SELECCIONA TU DONACIÓN</h2>
          <div className="section_middle_justify mensajeDonacion  ">
            <div className="options">
              <Button
                className={tipoDonacion ? '' : 'active'}
                onClick={handleUnica}
                variant="outlined"
              >
                Única
              </Button>
              <Button
                className={tipoDonacion ? 'active' : ''}
                onClick={handleMensual}
                variant="outlined"
              >
                Mensual
              </Button>
            </div>
            <div className="options">
              <Button
                className={tipoMoneda ? '' : 'active'}
                onClick={handlePEN}
                variant="outlined"
              >
                PEN
              </Button>
              <Button
                className={tipoMoneda ? 'active' : ''}
                onClick={handleUSD}
                variant="outlined"
              >
                USD
              </Button>
            </div>
            <div className="w_100 prices">
              {montos.map((value, index) => {
                return (
                  <PriceCard
                    key={index}
                    className="priceCard"
                    tipoDonacion={tipoDonacion}
                    tipoMoneda={tipoMoneda}
                    price={value.monto}
                    imagen={value.imagen}
                    setmonto={handleClickOpen}
                  />
                )
              })}
            </div>
            {
              <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title" className="tituloModal">
                  {`Muchas gracias por ${
                    tipoDonacion ? 'suscribirte a ' : 'realizar'
                  } la donación.`}
                </DialogTitle>
                <DialogContent>
                  <div id="alert-dialog-description">
                    <div className="avisoDonacion">
                      El monto de la donación {tipoDonacion ? 'mensual' : ''} es
                      de{' '}
                      <strong>
                        {tipoMoneda ? '$' : 'S/. '}
                        {monto}
                      </strong>
                      {!tipoMoneda ? (
                        <div className="w_100 aviso">
                          La plataforma generará el cobro en dólares por lo que
                          el monto de S/. {monto}{' '}
                          <strong>
                            se convertirá al tipo de cambio S/.{' '}
                            {parseFloat(cambioDolar).toFixed(2)} al valor de $
                            {parseFloat(monto / cambioDolar).toFixed(2)}
                          </strong>
                          .
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    {tipoDonacion ? (
                      montos.map((value) => {
                        const montoArray = value.monto
                        let linkSuscription = ''
                        if (montoArray === monto) {
                          if (tipoMoneda) {
                            linkSuscription = value.suscriptionUsd
                          } else {
                            linkSuscription = value.suscriptionPen
                          }
                          return (
                            <a
                              href={linkSuscription}
                              // target="_blank"
                              rel="noopener noreferrer"
                              className="suscribete"
                              style={{ textDecoration: 'none' }}
                            >
                              <Button
                                className="suscribirte"
                                variant="outlined"
                              >
                                Suscribirte
                              </Button>
                            </a>
                          )
                        }
                        return null
                      })
                    ) : (
                      <PayPalButton
                        amount={
                          tipoMoneda
                            ? monto
                            : parseFloat(monto / cambioDolar).toFixed(2)
                        }
                        onSuccess={(details, data) => {
                          setgracias(true)
                          handleClose()
                        }}
                        options={{
                          clientId: idCliente,
                        }}
                      />
                    )}
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    className="aceptar"
                    variant="outlined"
                  >
                    {tipoDonacion ? 'Cancelar' : 'Aceptar'}
                  </Button>
                </DialogActions>
              </Dialog>
            }
          </div>
          {gracias ? (
            <div className="gracias">
              <Header />
              <section>
                <h2>Muchas gracias por tu donación {information.name}</h2>
                <p>
                  Tu apoyo nos ayuda a seguir ayudando a los niños y niñas de
                  nuestra casa hogar <strong>Padre Martinho</strong>
                </p>
              </section>
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default DonateProcess



