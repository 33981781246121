import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import { makeStyles } from '@material-ui/core/styles'
import './styles.sass'
import PersonalInformation from '../personalInformation'
import DonateProcess from '../donateProcess'

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: '#ffffff',
    color: '#000',
    fontFamily: 'Poppins',
    display: 'block',
    '&:focus': {
      backgroundColor: '#ffffff',
    },
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
}))

const Wizzard = () => {
  const classes = useStyles()

  const [nav, setnav] = useState([
    { item: 'Información Personal', icon: <AccountCircleIcon />, active: true },
    {
      item: 'Proceso de Donación',
      icon: <CreditCardIcon />,
      active: false,
    },
  ])

  const [information, setinformation] = useState({})

  const handleClickNav = (id) => {
    const nuevoNav = []
    nav.map((value, index) => {
      const data = {
        item: value.item,
        icon: value.icon,
        active: index === id ? true : false
      }
      nuevoNav.push(data)
      return 0
    })
    setnav(nuevoNav)
  }

  // const handleUpdateName = (fnUpdateName) => {
  //   fnUpdateName(getName())
  // }

  return (
    <div className="section_middle_center wizzard">
      <ul className="section_middle_center generalNav">
        {nav.map((value, index) => {
          return (
            <li key={index} className={value.active ? 'active' : ''}>
              <Button
                variant="contained"
                disableElevation
                className={`section_middle_center ${classes.button}`}
                onClick={() => {
                  handleClickNav(index)
                }}
              >
                {value.icon}
                <div className="w_100 textoNav">{value.item}</div>
              </Button>
            </li>
          )
        })}
      </ul>
      <div className="contenedor w_100 section_middle_center">
        <PersonalInformation
          className={nav[0].active ? '' : 'hidden'}
          fnNext={handleClickNav}
          fnSetInfo={setinformation}
        />
        <DonateProcess
          information={information}
          className={nav[1].active ? '' : 'hidden'}
        />
      </div>
    </div>
  )
}

export default Wizzard
