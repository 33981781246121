import React from 'react'
import { useForm } from 'react-hook-form'
import './styles.sass'
// import { PayPalButton } from 'react-paypal-button-v2'
// import { token, urlApi } from '../../settings/apiSettings'

const PersonalInformation = (prop) => {
  const { className, fnNext, fnSetInfo } = prop
  const { register, handleSubmit, errors } = useForm()
  
  // const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  const getData = (data) => {
    // console.log(data)
    fnNext(1)
    fnSetInfo(data)
  }

  return (
    <div
      className={`w_80 section_middle_center contenedorFormulario ${className}`}
    >
      <h1>Nos gustaría conocerte primero</h1>
      <form
        className="section_middle_center formulario"
        onSubmit={handleSubmit(getData)}
      >
        <div className="section_middle_justify w_100">
          <label htmlFor="name">Nombres:</label>
          <input
            type="text"
            name="name"
            ref={register({
              required: { value: true, message: 'El nombre es requerido' },
            })}
          />
          {errors.name && (
            <span className="errorMsj">{errors.name.message}</span>
          )}
        </div>
        <div className="section_middle_justify w_100">
          <label htmlFor="lastname">Apellidos:</label>
          <input
            type="text"
            name="lastname"
            ref={register({
              required: { value: true, message: 'El apellido es requerido' },
            })}
          />
          {errors.lastname && (
            <span className="errorMsj">{errors.lastname.message}</span>
          )}
        </div>
        <div className="section_middle_justify w_100">
          <button className="button">Continuar</button>
        </div>
      </form>
    </div>
  )
}

export default PersonalInformation
