import React from 'react'
import Header from './layout/header'
import Wizzard from './layout/wizzard'

const App = () => {
  return (
    <div className="section_middle_justify">
      <Header />
      <Wizzard />
    </div>
  )
}

export default App
