import React from 'react'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import './styles.sass'

const PriceCard = (prop) => {
  const { className, tipoMoneda, price, tipoDonacion, imagen, setmonto } = prop

  const setearMonto = () => {
    setmonto(price)
  }

  return (
    <Card className={className}>
      <CardActionArea
        onClick={() => {
          setearMonto()
        }}
      >
        <CardMedia
          image={imagen}
          title="Contemplative Reptile"
          className="media"
        />
        <CardContent className="contentPrice">
          <Typography gutterBottom variant="h5" component="h2">
            {tipoMoneda ? 'USD' : 'PEN'}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            <span className="tipoMomeda">{tipoMoneda ? '$' : 'S/.'}</span>
            <strong className="price">{price}</strong>
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className="section_middle_center actions">
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            setearMonto()
          }}
        >
          {tipoDonacion ? 'Suscribirse' : 'Donar'}
        </Button>
      </CardActions>
    </Card>
  )
}

export default PriceCard